<template>
    <div class="container my-24 my-lg-40">
      <div class="row mb-24">
        <header class="col">
          <h2>Ваш список тестов</h2>
        </header>
      </div>

      <section class="row mb-24">
        <div class="col">
          <v-card
            tile
            class="px-16"
          >
            <div class="row align-items-center">
              <div class="col py-16 h4">
                Инструкция к тесту
              </div>

              <div class="col-auto">
                <v-button>
                  Читать
                </v-button>
              </div>
            </div>
          </v-card>
        </div>
      </section>

      <section class="row align-items-center justify-content-between mb-16">
        <div class="col-12 col-lg-auto order-lg-1 mb-8 mb-lg-16">
          <v-card
            tile
            class="px-16 d-flex align-items-center justify-content-between cursor-pointer"
            @click="toggleInstruction"
          >
              <div class="py-16 pr-16 text-nowrap">
                <b class="text-color-primary">
                  {{ instruction.isShow ? 'Скрыть' : 'Читать' }} инструкцию к тексту
                </b>
              </div>

              <div>
                <v-icon-svg
                  class="a-flip-y svg-stroke-ui-main-1"
                  :class="{'a-flip-y_active': instruction.isShow}"
                  fill="none"
                  view-box="0 0 11 5"
                  title="svg-icon-chevron"
                  width="12"
                  height="12"
                >
                  <svg-icon-chevron/>
                </v-icon-svg>
              </div>
          </v-card>
        </div>

        <article
          class="col-12 order-lg-3 mb-lg-24"
          v-if="instruction.isShow"
        >
          <v-card class="px-16 py-32 p-lg-32">
            <p>Добрый день!</p>

            <p>
              Вы приглашены к прохождению теста управленческого потенциала.
              Данный тест измеряет качества, прогнозирующие успех человека
              в новых рабочих условиях и обстоятельствах.
            </p>

            <hr class="my-24">

            <p>
              <strong>
                Пожалуйста, учитывайте следующие особенности теста,
                связанные с ограничением времени:
              </strong>
            </p>

            <ul class="ul ul_type_lines">
              <li>
                <strong>Между разделами можно делать паузы.</strong>
              </li>
              <li>
                <strong>Внутри большинства разделов паузы делать нельзя</strong> — время на их прохождение ограничено. Если Вы закроете
                тест во время прохождения одного из блоков, таймер продолжет идти.
              </li>
              <li>
                <strong>Таймер расположен на виджете с правой стороны</strong>, рядом с тестовой частью.
              </li>
              <li>
                <strong>Количество минут на прохождение теста указывается
                  в дополнительных инструкциях в каждом разделе.</strong>
              </li>
              <li>
                <strong>Общее время прохождения теста — 120 минут.</strong>
              </li>
              <li>
                <strong>Приготовьте заранее ручку / карандаш, бумагу, калькулятор.</strong>
                В одном разделе теста необходимо производить математические вычисления.
              </li>
              <li>
                <strong>Ответ засчитывается только при нажатии на кнопку  «Ответить».</strong>
                Таким образом, Вы подтверждаете свой ответ.
              </li>
            </ul>

            <hr class="my-24">

            <p>
              Если у Вас возникнут вопросы или затруднения в ходе работы с системой
              — напишите в службу поддержки в вашем личном кабинете.
            </p>

            <p class="mb-0 mt-24">
              <strong>Желаем Вам удачи!</strong>
            </p>
          </v-card>
        </article>

        <div class="col-12 col-lg-auto order-lg-2 mb-8 mb-lg-16 mt-8 mt-lg-0">
          <v-card
            tile
            class="p-16 d-flex align-items-center"
          >
                <v-icon-svg
                  class="svg-stroke-ui-3 mr-8"
                  view-box="0 0 21 24"
                  title="svg-icon-clock"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <svg-icon-clock></svg-icon-clock>
                </v-icon-svg>

                <span class="d-inline-block">
                  Сейчас таймер отключен, можете отдохнуть, если хотите
                </span>
          </v-card>
        </div>
      </section>

      <section class="row row-cols-1">
        <div
          v-for="(test, index) in tests"
          :key="test.title"
          class="col mb-4"
        >
          <v-card
            tile
            class="px-16 bg-color-background-1"
            :class="{
              'bg-color-background-1': test.status === 'closed',
              'bg-color-background-3': test.status === 'open' || test.status === 'done'
            }"
          >
            <div class="row align-items-center">
              <div class="col-auto h4 my-8 my-md-16">{{index + 1}}</div>

              <div class="col my-8 my-md-16">
                <div class="row align-items-center">
                  <div class="col-12 col-md-9 mb-8 mb-md-0 h4">
                    {{test.title}}
                  </div>

                  <div class="col-12 col-md-3">
                    <v-icon-svg
                      class="svg-stroke-primary"
                      title="svg-icon-clock"
                      view-box="0 0 21 24"
                      width="1rem"
                      height="1rem"
                    >
                      <svg-icon-clock></svg-icon-clock>
                    </v-icon-svg>

                    {{test.time}}
                  </div>
                </div>
              </div>

              <div class="col-4 col-md-3 text-right">
                  <div class="text-nowrap" v-if="test.status === 'done'">
                    <v-icon-svg
                      class="mr-8 svg-stroke-ui-main-1"
                      view-box="0 0 11 8"
                      title="svg-icon-checked"
                      width=".6875rem"
                      height=".5rem"
                    >
                      <svg-icon-checked></svg-icon-checked>
                    </v-icon-svg>

                    <strong class="text-color-primary">Пройдено!</strong>
                  </div>

                  <template v-if="test.status === 'open'">
                    <v-button>Начать</v-button>
                  </template>
              </div>
            </div>
          </v-card>
        </div>
      </section>
    </div>
</template>

<script>
import {
  VButton,
  VCard,
  VIconSvg
} from '@components/base'
import SvgIconClock from '@components/icons/SvgIconClock'
import SvgIconChecked from '@components/icons/SvgIconChecked'
import SvgIconChevron from '@components/icons/SvgIconChevron'

export default {
  name: 'SSections',

  components: {
    SvgIconChecked,
    SvgIconChevron,
    SvgIconClock,
    VButton,
    VCard,
    VIconSvg
  },

  data () {
    return {
      instruction: {
        isShow: false
      },

      tests: [
        {
          title: 'Тренировочные задания',
          time: '20 мин',
          status: 'done'
        },
        {
          title: 'Обработка информации',
          time: '25 мин',
          status: 'done'
        },
        {
          title: 'Анализ идеи',
          time: '35 мин',
          status: 'open'
        },
        {
          title: 'Принятие решений',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Кейсы',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Мнения и отношения',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Лидерский стиль',
          time: '5 мин',
          status: 'closed'
        }
      ]
    }
  },

  methods: {
    toggleInstruction () {
      this.instruction.isShow = !this.instruction.isShow
    }
  }
}
</script>
